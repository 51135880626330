<template>
    <v-card flat>
                <v-card-text>
                    <v-simple-table
                        v-if="renderComponent"
                        fixed-header
                        height="400px"
                    >

                        <template v-slot:top>
                            
                            <emc-table-icon-edit
                                icon="mdi-plus"
                                toolTip="Incluir Professor"
                                @click="showAddItem()"  
                            />
                            
                            <v-progress-linear
                                indeterminate
                                :color="loadingTable ? 'yellow darken-2' : ''"
                            />
                            
                        </template>

                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left">Professor</th>
                                <th class="text-left">CH</th>
                                <th class="text-left">Status</th>
                                <th class="text-center">Período</th>
                                <th class="text-left">Usuário</th>
                                <th class="text-left">Data</th>
                                <th class="text-center"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, i) in items"
                                :key="i"
                            >
                                <td>{{ item | teacherName }}</td>
                                <td>{{ item.work_load }}</td>
                                <td>{{ item.status ? item.status.name : null }}</td>
                                <td>{{ item.date_start | date }} - {{ item.date_end | date }}</td>
                                <td>{{ item.user ? item.user.name : null }}</td>
                                <td>{{ item.updated_at | dateTime }}</td>
                                <td>
                                    
                                    <!-- Caso o item já tenha sido exportado -->
                                    
                                    <emc-table-icon
                                        v-if="item.exported && (item.attribution_status_id == 1 || item.attribution_status_id == 5)"
                                        color="purple"
                                        icon="mdi-account-convert"
                                        tool-tip="Finalizar atribuição"
                                        @click="onShowEdit(item, 'finalize')"  
                                    />
                                    
                                    <emc-table-icon
                                        v-if="item.exported && (item.attribution_status_id == 1 || item.attribution_status_id == 4 || item.attribution_status_id == 5)"
                                        color="error"
                                        icon="mdi-account-remove"
                                        tool-tip="Remover atribuição"
                                        @click="onShowEdit(item, 'remove')"  
                                    />

                                    <!-- Fim -->

                                    <!-- Caso o item não tenha sido exportado -->

                                    <emc-table-icon-edit
                                        v-if="!item.exported && (item.attribution_status_id == 1 || item.attribution_status_id == 4 || item.attribution_status_id == 5)"
                                        @click="onShowEdit(item, 'edit')"  
                                    />

                                    <emc-table-icon-delete
                                        v-if="!item.exported && (item.attribution_status_id == 1 || item.attribution_status_id == 4 || item.attribution_status_id == 5)"
                                        @click="onShowDelete(item)"
                                    />

                                    <!-- Fim -->

                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>

                <sys-teacher-attribution-register :type="type" :model="itemEdit" :show.sync="showForm" @onCompleted="onCompleted()" />
                <sys-teacher-attribution-register :type="type" :model="itemNew" new-item :show.sync="showFormNewtItem" @onCompleted="onCompleted()" />

                <emc-alert-modal 
                    title="Deseja excluir a atribuição?" text="Excluir" 
                    :show.sync="showDelete"
                    :disabled="loadingDelete"
                    >
                    <template v-slot:actions>
                        <v-btn :disabled="loadingDelete" :loading="loadingDelete" color="error" @click.prevent.stop="deleteAttribution()">Excluir <v-icon right dark>mdi-delete</v-icon></v-btn>
                    </template>
                    </emc-alert-modal>
        </v-card>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    name: 'SysTeacherAttributionStatus',
    data: function() {
        return {
            loading: false,
            loadingTable: false,
            loadingDelete: false,
            itemEdit: null,
            itemNew: null,
            itemDelete: null,
            showDelete: false,
            renderComponent: true,
            showForm: false,
            type: 'edit',
            showFormNewtItem: false,
            items: [],
        }
    },
    props: {
        model: {

        },
    },
    created() {
        this.items = this.model.attributions;
    },
    watch: {
        model(val){
            this.items = val.attributions;
        }
    },
    methods: {
        ...mapActions('attribution', ['ActionDeleteAttribution', 'ActionFindAttributions']),

        showAddItem(){
            
            this.itemNew = {
                id:                     0,
                grid_id:                this.model.id,
                attribution_status_id:  1,
                work_load:              this.model.work_load_pay,
                date_start:             null,
                date_end:               null,
                teacher_id:             null
            };

            this.showFormNewtItem = true;
        },
        
        onShowEdit(item, type){
            this.itemEdit = item;
            this.type = type;
            this.showForm = true;
        },
        
        onShowDelete(item){
            this.itemDelete = item;
            this.showDelete = true;
        },

        onCompleted(){
            this.showForm = false;
            this.getItems();
            this.$emit('onCompleted');
        },

        getItems(){

            
            this.loadingTable = true;
            
            let payload = {
                find_columns: {
                    grid_id: this.itemEdit ? this.itemEdit.grid_id : this.itemNew.grid_id,
                },
                with: 'teacher,status,user',
                column_order: 'updated_at',
            }
            
            this.ActionFindAttributions(payload)
                    .then((res) => {
                        this.items = res.data;
                    })
                    .catch((error) =>{
                       
                    })
                    .finally(() => {
                        this.loadingTable = false;
                    });
        },

        deleteAttribution(){
            
            this.loadingDelete = true;
            
            this.ActionDeleteAttribution({id: this.itemDelete.id})
                    .then((res) => {
                        const index = this.items.indexOf(this.itemDelete);
                        this.items.splice(index, 1);
                    })
                    .catch((error) =>{
                       
                    })
                    .finally(() => {
                        this.showDelete = false;
                        this.loadingDelete = false;
                    });
        },

        forceRerender () {
            
            this.renderComponent = false;

            this.$nextTick(() => {
                this.renderComponent = true;
            });

        },
        
    },
}
</script>